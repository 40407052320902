pre {
  background-color: darkgrey;
  margin: 10px;
  padding: 10px;
  white-space: break-spaces;
}

.qb-lite .group--header:hover .group--header .group--drag-handler,
.qb-lite .group--header:hover .group--header .group--actions,
.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions {
  opacity: 1;
}

.group {
  padding-left: 0px !important;
  padding: 8px !important;
  background: #fcfcfc !important;
  /* margin-top: 20px !important; */
}

.group-or-rule-container {
  margin-bottom: 10px !important;
}

/* .MuiButton-contained {
  width: 64px;
  height: 35px;
  font-size: 16px !important;
} */

.MuiButton-textSizeSmall {
  font-size: 15px !important;
  margin-right: 8px !important;
} */

.rule {
  border: 1px solid #cccbcb;
  box-shadow: 0 5px 8px 0 #efeeee;
}

.group {
  background: #d7dfe857 !important;
  border: 1px solid #cccbcb;
}

.group--children > .group-or-rule-container > .group-or-rule::before,
.group--children > .group-or-rule-container > .group-or-rule::after {
  border-color: #848484 !important;
}

.MuiInput-root {
  position: relative;
  width: 200px;
  margin-right: 25px;
  margin: 8px;
}

/* .MuiButton-contained {
  background-color: #f7f7f7bf !important;
  border: 1px solid #cccbcb !important;
} */

.query-builder {
  margin: 0px;
}

.newFiler::before {
  position: absolute;
  left: 50%;
  content: " ";
  display: block;
  height: 25px;
  z-index: 1;
  border-left: 1px dashed #acb6be;
  transform: translateX(-50%);
}

.filter::before {
  position: absolute;
  left: 50%;
  content: " ";
  display: block;
  height: 25px;
  z-index: 1;
  border-left: 1px dashed #acb6be;
  transform: translateX(-50%);
}

.switchParent::after {
  position: absolute;
  left: 50%;
  top: 100%;
  content: " ";
  display: block;
  height: 25px;
  z-index: 1;
  border-left: 1px dashed #acb6be;
  transform: translateX(-50%);
}
